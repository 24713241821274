import {marketplaceTypes} from "../../../../../../marketplace/di/types";
import {MatchingUseCase} from "../../../application";
import {Container} from "inversify";
import {Ref} from "vue";
import {useSessionStorage} from "@vueuse/core";
import {Workflow, WorkflowStep} from "../../../models/workflow";
import {FilterCatalogUseCase} from "../../../../../../marketplace/catalog/application";
import {Service} from "../../../../../../marketplace/models/catalog";
import {MatchedCatalogItem} from "../../../../../../marketplace/models/matching";

const isLoading = ref(false);
const version = ref('v3');
const workflow: Ref<Workflow> = useSessionStorage('matching', {
  id: '',
  currentState: WorkflowStep.Initial,
  dealId: '',
  countOfMatchedPsychologists: 0,
  metadata: {},
  requests: [],
});
const unsavedCart = useSessionStorage('unsavedCart', {});
const catalogItem = useSessionStorage('matchedItem', {});
const formData = useSessionStorage('matchingFormData', {
  initial: getInitialSchema(),
  psychologist: getPsychologistSchema(),
  category: getCategorySchema(),
  requests: getRequestsSchema(),
  results: getResultsSchema(),
  booking: getBookingSchema(),
});
const countOfMatchedPsychologists = ref(null);
const categories = ref([]);
const requests = ref([]);
const isRequestMode = useSessionStorage('request', false);

const steps = computed(() => {
  if (version.value === 'v2') {
    return ['initial', 'psychologist', 'category', 'requests', 'finished', 'booking'];
  } else {
    if (workflow.value.metadata?.audience === 'child') {
      return ['initial', 'psychologist', 'childhood_age', 'category', 'requests', 'finished', 'booking'];
    } else if (workflow.value.metadata?.audience === 'couple') {
      return ['initial', 'psychologist', 'category', 'requests', 'finished', 'booking'];
    } else {
      return ['initial', 'psychologist', 'wellbeing_wheel', 'finished', 'booking'];
    }
  }
})

function getInitialSchema() {
  return {
    customerAge: 18,
    customerGender: null,
    targetAudience: null,
    hasTherapyExperience: null,
  }
}

function getPsychologistSchema() {
  return {
    psychologistGender: null,
    languages: [],
  }
}

function getCategorySchema() {
  return {
    categories: [],
  }
}

function getRequestsSchema() {
  return {}
}

function getResultsSchema() {
  return [];
}

function getBookingSchema() {
  return {
    name: null,
    password: null,
    email: null,
    phone: null,
    oauth: null,
    comment: null,
    psychologist: null,
    service: unsavedCart.value.service,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    timeSlots: [],
  }
}

export function useMarketplaceMatching() {
  // @ts-ignore
  const { $container, $visitorId }: {$container: Container} = useNuxtApp();
  const matchingUseCase: MatchingUseCase = $container.get<MatchingUseCase>(marketplaceTypes.MatchingUseCase);
  const filterCatalogUseCase: FilterCatalogUseCase = $container.get<FilterCatalogUseCase>(marketplaceTypes.FilterCatalog);

  async function reset() {
    workflow.value = {
      id: '',
      currentState: WorkflowStep.Initial,
      dealId: '',
      countOfMatchedPsychologists: 0,
      metadata: {},
      requests: [],
    }
    formData.value = {
      initial: getInitialSchema(),
      psychologist: getPsychologistSchema(),
      category: getCategorySchema(),
      requests: getRequestsSchema(),
      results: getResultsSchema(),
      booking: getBookingSchema(),
    }
    countOfMatchedPsychologists.value = null;
    categories.value = [];
    requests.value = [];
    isRequestMode.value = false;
  }

  async function createWorkflow(timeZone: string) {
    isLoading.value = true;

    try {
      workflow.value = await matchingUseCase.createWorkflow(version.value, timeZone, $visitorId);
    } catch (e) {
      throw e;
    } finally {
      isLoading.value = false;
    }
  }

  async function fetchCountPsychologists(transition, payload) {
    workflow.value.countOfMatchedPsychologists = await matchingUseCase.getCountPsychologists(workflow.value.id, transition, payload);
  }

  async function fetchResults() {
    const catalog = await filterCatalogUseCase.execute({matching: workflow.value.id});
    formData.value.results = [];
    catalog.items.forEach(item => {
      let matchedItem: MatchedCatalogItem = Object.assign({
        matchedRequestIds: [],
        customerRequests: [],
        allRequestIds: [],
      }, item);
      item.services.forEach((service: Service) => {
        if (service.requests) {
          Object.keys(service.requests).forEach(requestId => {
            matchedItem.allRequestIds.push(requestId);
            if (workflow.value.requests.includes(requestId) && !matchedItem.matchedRequestIds.includes(requestId)) {
              matchedItem.matchedRequestIds.push(requestId);
            }
          });
        }
      });
      matchedItem.customerRequests = requests.value.filter(request => workflow.value.requests.includes(request.id));
      matchedItem.customerRequests.sort((a, b) => {
        if (matchedItem.matchedRequestIds.includes(a.id)) {
          return -1;
        }
        return 0;
      });

      formData.value.results.push(matchedItem);
    });
    formData.value.results.sort((a, b) => {
      if (a.matchedRequestIds.length > b.matchedRequestIds.length) {
        return -1;
      } else {
        return 1;
      }
    });
  }

  async function sendToWorkflow(transition: string, payload: any) {
    isLoading.value = true;

    try {
      const response = await matchingUseCase.commitWorkflow(workflow.value.id, transition, payload);
      workflow.value.currentState = response.currentState;
      workflow.value.countOfMatchedPsychologists = response.countOfMatchedPsychologists;
      workflow.value.metadata = response.metadata;
      workflow.value.requests = response.requests;

      if (workflow.value.currentState === 'finished') {
        await fetchResults();
      }
    } catch (e) {

    } finally {
      isLoading.value = false;
    }
  }

  async function fetchCategories() {
    isLoading.value = true;

    try {
      categories.value = await matchingUseCase.getCategories(workflow.value.id);
    } catch (e) {
      throw e;
    } finally {
      isLoading.value = false;
    }
  }

  async function fetchRequests() {
    isLoading.value = true;

    try {
      requests.value = await matchingUseCase.getRequests(workflow.value.id);
    } catch (e) {
      throw e;
    } finally {
      isLoading.value = false;
    }
  }

  return {
    isLoading,
    steps,
    isRequestMode,
    workflow,
    countOfMatchedPsychologists,
    categories,
    requests,
    formData,
    unsavedCart,
    catalogItem,
    reset,
    createWorkflow,
    sendToWorkflow,
    fetchCountPsychologists,
    fetchCategories,
    fetchRequests,

    getInitialSchema,
    getPsychologistSchema,
    getCategorySchema,
    getRequestsSchema,
    getResultsSchema,
    getBookingSchema,
  }
}
